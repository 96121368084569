<template>
  <div id="krave-contact" class="col-12 px-0 pb-5">
    <div id="contact" class="container-fluid contact pageTitle">
      <parallax>
        <img src="/assets/img/krave-studios-contact-header.jpg" alt="" />
      </parallax>
      <h1 class="pageTitle">Contact</h1>
    </div>
    <div class="contact-page container">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <div class="row">
            <div class="col-12 py-5 text-center">
              <i class="fa fa-share-alt fa-4x" aria-hidden="true"></i>
            </div>
            <div class="col-12 pb-5 text-center">
              <h4 class="text-uppercase">FOLLOW US</h4>
              <p class="contact-text lighter-color">
                Follow us on social media for the latest Krave Studios news.
              </p>
              <div class="d-flex justify-content-center">
                <span class="px-3">
                  <a href="http://facebook.com/kravestudios" class="fa-icons" target="_blank">
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                </span>
                <span class="px-3">
                  <a href="http://twitter.com/kravestudios" class="fa-icons" target="_blank">
                    <i class="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                </span>
                <span class="px-3">
                  <a href="http://instagram.com/kravestudios" class="fa-icons" target="_blank">
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="row">
            <div class="col-12 py-5 text-center">
              <i class="fa fa-envelope fa-4x" aria-hidden="true"></i>
            </div>
            <div class="col-12 pb-5 text-center">
              <h4 class="text-uppercase">EMAIL</h4>
              <p class="contact-text lighter-color">
                You can contact us on the following email address:
              </p>
              <a href="mailto:info@kravestudios.com" class="krave-mailto">info@kravestudios.com</a>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="row">
            <div class="col-12 py-5 text-center">
              <i class="fa fa-map-marker fa-4x" aria-hidden="true"></i>
            </div>
            <div class="col-12 pb-5 text-center">
              <h4 class="text-uppercase">KRAVE STUDIOS</h4>
              <address class="krave-address">
                1819 West Ave, bay 5, Miami Beach, FL 33139
              </address>
              <p class="contact-text lighter-color">
                All Business and Media Collaboration Inquiries:
              </p>
              <p class="contact-text lighter-color">
                Vitaly Reznik: <a href="mailto:vreznik@kravestudios.com" class="krave-mailto">vreznik@kravestudios.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Parallax from "vue-parallaxy";
export default {
  name: "Contact",
  components: {
    Parallax,
  },
  created() {
    this.$store.commit("SET_FOOTER", true);
    this.footer = this.$store.state.footer;
  },
};
</script>

<style scoped>
#contact {
  width: 100vw;
  height: 45vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#contact h1.pageTitle {
  font-family: "Lato", sans-serif;
  font-size: 50px;
  font-weight: lighter;
  color: #fff;
  margin: auto;
  text-transform: uppercase;
  position: absolute;
  text-align: center;
  width: 100vw;
}
#krave-contact .krave-address {
  font-family: "Lato", Lato, "Ubuntu", sans-serif;
  font-size: 1.2rem;
  color: #000000;
  font-weight: bold;
}
#krave-contact p.lighter-color, #krave-contact a.krave-mailto {
  font-family: "Lato", Lato, "Ubuntu", sans-serif;
  font-size: 1.2rem;
  color: #838383;
}
#krave-contact a.krave-mailto {
  text-decoration: none;
}
#krave-contact a.krave-mailto:hover,
#krave-contact a.krave-mailto:active,
#krave-contact a.krave-mailto:focus {
  color: #000000;
  text-decoration: none;
}

.Masthead {
  width: 100vw;
}
#krave-contact a.fa-icons {
  color: #838383;
  font-weight: normal;
  font-size: 2rem;
  text-decoration: none;
}
#krave-contact a.fa-icons:hover,
#krave-contact a.fa-icons:active,
#krave-contact a.fa-icons:focus {
  color: #000000;
  text-decoration: none;
}
</style>
